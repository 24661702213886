import React, {useState, useEffect} from "react";

import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, Link} from "@material-ui/core";
import {CompTextField} from "../../../../components/TextField";
import * as PropTypes from "prop-types";
import {CompAccordion} from "../../../../components/Accordion";
import {CompDatePicker} from "../../../../components/DatePicker";
import {CompTimePicker} from "../../../../components/TimePicker";
import {CompCheckbox} from "../../../../components/Checkbox";
import {ButtonMain} from "../../../../components/ButtonMain";
import {connect} from "react-redux";
import {CompRadioGroup} from "../../../../components/RadioGroup";
import {
  getFeedbackReport,
  deleteFeedback,
  listFeedback,
  recreateLinksForFunction,
} from "../../function.actions";
import { handleDateTimeChange } from "../functionFormComponentUtils";
import { formatISODateStr } from "../../../../utils";
import Paper from "@material-ui/core/Paper";
import {useTranslation} from "react-i18next";
import {ageOptions, genderOptions} from "../../../feedback/feedbackOptions";
import CustomSnackbar from "../../../../components/CustomSnackbar";

const FeedbackComponent = ({
  func,
  recreateLinksForFunction,
  getFeedbackReport,
  deleteFeedback,
  listFeedback,
  props
}) => {
  const {
    label,
    editorActivated,
    selectedFunction,
    fieldErrors = {},
    allPartitionsOpen = false,
    handleAccordionChange,
    accordionHasError,
    handleAlertClose,
    handleMultiselectChange,
    activeAccordion,
    setShowParticipantInformation,
    onChange,
    disableAll
  } = props;

  const {t} = useTranslation('translation');

  const [languageOption, setLanguageOption] = useState("fi");
  const [feedbackOption, setFeedbackOption] = useState("1");

  const [fetchFeedack, setFetchFeedback] = useState(true);
  const [listDone, setListDone] = useState(false);
  const [feedbackDone, setFeedbackDone] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (listDone && feedbackDone) {
      setFeedbackDone(false);
      setListDone(false);
      setShowParticipantInformation(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listDone, feedbackDone])

  useEffect(() => {
    if (fetchFeedack) {
        setFetchFeedback(false);
        func.feedbackReport = null;
        handleFeedbackFetch();
        handleListFeedback();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [fetchFeedack, selectedFunction])
  

  const getCode = (link = false) => {
    if (selectedFunction.id && func["linkupdate"+selectedFunction.id]) {
        const code = func["linkupdate"+selectedFunction.id];
        if (selectedFunction.feedbackCode !== code) {
            selectedFunction.feedbackCode = code;
        }
    }
    var loc = window.location.href+"/";
    loc.replace("function", "feedback");
    return link ? loc + selectedFunction.feedbackCode: selectedFunction.feedbackCode;
}

const getLink = () => {
  return "/feedback/"+getCode()+"-"+languageOption+"-"+feedbackOption+"-preview";
}

  const handleQRcodeLinkCopy = () => {
    var new_link = getLink().replace(/-preview/g, '');
    navigator.clipboard.writeText(new URL(window.location.origin + new_link))
    setOpen(true)
  }

  const handleDeleteFeedback = (row) => {
    deleteFeedback(row);
  }

  const handleListFeedback = () => {
    if (selectedFunction.id) {
      listFeedback(selectedFunction.id);
    }
    setListDone(true);
  }

  const handleFeedbackFetch = () => {
    if (selectedFunction.id) {
      getFeedbackReport(selectedFunction.id);
    }
    setFeedbackDone(true);
  }

  useEffect(() => {
    if (fetchFeedack) {
      setFetchFeedback(false);
      func.feedbackReport = null;
      handleFeedbackFetch();
      handleListFeedback();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchFeedack, selectedFunction])

  const feedbackChoices = [
    {
      label: t('function.gender_and_age'),
      key: "feedbackGenderAndAge",
      value: selectedFunction.feedbackGenderAndAge
    },
    {
      label: t('function.function_rating'),
      key: "feedbackFunctionRating",
      value: selectedFunction.feedbackFunctionRating
    },
    {
      label: t('function.function_comment'),
      key: "feedbackFunctionComment",
      value: selectedFunction.feedbackFunctionComment
    }
  ];


  const languageOptions = [
    {
      label: t('common.fi'),
      value: "fi"
    },
    {
      label: t('common.sv'),
      value: "sv"
    }
  ];

  const feedbackOptions = [
    {
      label: t('function.signAndfeedback'),
      value: "1"
    },
    {
      label: t('function.onlyFeedback'),
      value: "2"
    },
    {
      label: t('function.onlySignIn'),
      value: "3"
    }
  ];

  const generateLinks = (event, id) => {
    event.preventDefault();
    recreateLinksForFunction(id);
  }


  const feedbackLinksView = (
      <Grid item xs={12} sm={4}>
        <CompTextField
            label={t('function.feedback_code')}
            value={getCode()}
            disabled={true}
            name="feedbackLink"
        />
      </Grid>
  );

  

  const feedbackQRCodeView = (
      <Grid item xs={12} sm={8}>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <div className={'label'}>{t('function.languageOption')}</div>
            <CompRadioGroup
                name="languageOption"
                selected={languageOption}
                rows={languageOptions}
                onChange={(e) => setLanguageOption(e.target.value)}/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={'label'}>{t('function.feedbackOption')}</div>
            <CompRadioGroup
                name="feedbackOption"
                selected={feedbackOption}
                rows={feedbackOptions}
                onChange={(e) => setFeedbackOption(e.target.value)}/>
          </Grid>
          <Grid>
            <Grid item xs={12} sm={4} style={{marginBottom: "10%"}}>
              <Link onClick={() => window.open(getLink())} style={{cursor: 'pointer', whiteSpace: "nowrap"}}>
                {t('function.feedback_link')}
              </Link>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Link onClick={handleQRcodeLinkCopy} style={{cursor: 'pointer', whiteSpace: "nowrap"}}>
                {t('function.link_to_function')}
                <CustomSnackbar open={open} onClose={handleAlertClose} severity="success" message={t('common.link_copied')} />
              </Link>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
  );

  const feedbackItems = selectedFunction.feedbackList ? selectedFunction.feedbackList : [];

  const generateLinksView = (
      <Grid item xs={12} sm={12}>
        <ButtonMain
            key={'generate_new_code'}
            onClick={e => generateLinks(e, selectedFunction.id)}
            disabled={!editorActivated}>
          {t('function.generate_links')}
        </ButtonMain>
      </Grid>
  );

  return (
      <>
  <Grid item xs={12}>
    <CompAccordion
        id={'accordion-pt'}
        label={t('function.feedback_info')}
        onChange={(event, isExpanded) => handleAccordionChange(event, isExpanded, 'pt')}
        error={accordionHasError([
          'feedbackChoices',
          'startDateFeedback',
          'endDateFeedback'
        ]) ? t('errors.has error') : null}
        isExpanded={allPartitionsOpen || activeAccordion === 'pt'}
    >
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <CompCheckbox
              booleanmode={true}
              rows={feedbackChoices}
              selected={selectedFunction}
              disabled={disableAll}
              onChange={handleMultiselectChange}
              name={"feedbackChoices"}
          />
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <CompDatePicker
                label={`${t('function.start_date_feedback')}`}
                value={formatISODateStr(selectedFunction.startDatetimeFeedback)}
                disabled={disableAll}
                name="startDateFeedback"
                error={fieldErrors.startDatetimeFeedback}
                onChange={e => handleDateTimeChange(e, 'startDateFeedback', selectedFunction, onChange)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompDatePicker
                label={`${t('function.end_date_feedback')}`}
                value={formatISODateStr(selectedFunction.endDatetimeFeedback)}
                disabled={disableAll}
                name="endDateFeedback"
                error={fieldErrors.endDatetimeFeedback}
                onChange={e => handleDateTimeChange(e, 'endDateFeedback', selectedFunction, onChange)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6}>
            <CompTimePicker
                label={`${t('function.start_time_feedback')}`}
                value={selectedFunction.startDatetimeFeedback}
                disabled={disableAll}
                name="startTimeFeedback"
                error={fieldErrors.startDatetimeFeedback}
                onChange={e => handleDateTimeChange(e, 'startTimeFeedback', selectedFunction, onChange)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CompTimePicker
                label={`${t('function.end_time_feedback')}`}
                value={selectedFunction.endDatetimeFeedback}
                disabled={disableAll}
                name="endTimeFeedback"
                error={fieldErrors.endDatetimeFeedback}
                onChange={e => handleDateTimeChange(e, 'endTimeFeedback', selectedFunction, onChange)}
            />
          </Grid>
        </Grid>
        {selectedFunction.id && editorActivated && generateLinksView}
        {selectedFunction.id && feedbackLinksView}
        {selectedFunction.id && selectedFunction.feedbackCode && feedbackQRCodeView}
      </Grid>
    </CompAccordion>
  </Grid>
  {selectedFunction.id && (
      <Grid item xs={12}>
        <CompAccordion
            id={'accordion-pd'}
            label={t('function.feedback_data')}
            onChange={(event, isExpanded) => handleAccordionChange(event, isExpanded, 'pd')}
            error={accordionHasError([
              'feedbackChoices',
              'startDateFeedback',
              'endDateFeedback'
            ]) ? t('errors.has error') : null}
            isExpanded={allPartitionsOpen || activeAccordion === 'pd'}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12}>
              <TableContainer component={Paper} className={'CompSystemLogTable-container'}>
                <Table className={'CompSystemLogTable-table'} aria-label={label}>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('feedback.date')}</TableCell>
                      <TableCell>{t('feedback.age')}</TableCell>
                      <TableCell>{t('feedback.gender')}</TableCell>
                      <TableCell>{t('feedback.rating')}</TableCell>
                      <TableCell>{t('feedback.comment')}</TableCell>
                      <TableCell/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {feedbackItems.map((row, index) => (
                        <TableRow
                            hover
                            tabIndex={-1}
                            key={`row-${index}`}
                        >
                          <TableCell>{row.date}</TableCell>
                          <TableCell>{row.age !== null ? t(ageOptions.filter(obj => {return obj.value === row.age.toString();})[0].label) : ''}</TableCell>
                          <TableCell>{row.gender !== null ? t(genderOptions.filter(obj => {return obj.value === row.gender.toString();})[0].label) : ''}</TableCell>
                          <TableCell>{row.rating ? (row.rating === -1 ? t('feedback.not_answered') : row.rating) : ''}</TableCell>
                          <TableCell>{row.comment}</TableCell>
                          <TableCell>
                            <ButtonMain
                                key={'delete_feedback'}
                                onClick={e => handleDeleteFeedback(row.id)}>
                              {t('function.delete')}
                            </ButtonMain>
                          </TableCell>
                        </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </CompAccordion>
      </Grid>
  )}</>)
}

FeedbackComponent.propTypes = {
  label: PropTypes.string,
  editorActivated: PropTypes.bool,
  shared: PropTypes.bool,
  onChange: PropTypes.func,
  selectedFunction: PropTypes.object,
  selectedLocation: PropTypes.object,
  fieldErrors: PropTypes.object,
  actionButtons: PropTypes.array,
  functionTemplateButtons: PropTypes.array,
  allPartitionsOpen: PropTypes.bool,
  updateData: PropTypes.bool,
  youthData: PropTypes.object
};

const mapStateToProps = (state, ownProps) => {
  // return state;
  return {
    func: state.func,
    props: ownProps
  }
}
const mapDispatchToProps = dispatch => {
  return {
    getFeedbackReport: (eventId) => dispatch(getFeedbackReport(eventId)),
    deleteFeedback: (feedbackItemId) => dispatch(deleteFeedback(feedbackItemId)),
    listFeedback: (eventId) => dispatch(listFeedback(eventId)),
    recreateLinksForFunction: (id, onSuccess, onFailure) => dispatch(recreateLinksForFunction(id, onSuccess, onFailure)),
       
  };
}

const FeedbackForm = connect(mapStateToProps, mapDispatchToProps)(FeedbackComponent);

export {FeedbackForm};