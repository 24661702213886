import React from "react";
import * as PropTypes from "prop-types";
import {getFunctionIconUrl, getFunctionTypeLabel} from "../../root/root.utils";
import {formatDateStr, generateKey} from "../../../utils";
import {IconButton, MenuItem, Select} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {
    hasPermission,
    hasPermissionToHandleMunicipality,
    PERMISSION_EDIT_ANY_FUNCTIONS,
    PERMISSION_EDIT_OWN_FUNCTIONS,
    PERMISSION_EDIT_ANY_MUNICIPALITY_FUNCTION_TEMPLATE
} from "../../../permissions";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";


const CompFunctionCards = (props) => {
    const {
        functions = [],
        actions = [],
        rowClick = (e, row) => {
            console.log((e + "," + row));
        },
        changePage = (e) => {
            console.log(e);
        },
        authenticatedUser = {},
        initialRowsPerPage = 10,
        page,
        updateRowsPerPage,
        newMethod=false,
        municipalities
    } = props;

    const {t} = useTranslation('translation');


    const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);


    const handlePreviousPage = (event) => {
        if (newMethod) {
            changePage(event,null, rowsPerPage, 'backward', page === 'history');
        }
        else {
            if(functions.length === 0) {
                changePage(event,null, rowsPerPage, 'backward', page === 'history');
                return;
            }
            const first = functions[0];

            changePage(event,first.id, rowsPerPage, 'backward', page === 'history');
        }

    };

    const handleNextPage = (event) => {
        if (newMethod) {
            changePage(event,null, rowsPerPage, 'forward', page === 'history');
        }
        else {
            if(functions.length === 0) {
                return;
            }
            const last = functions[functions.length-1];

            if (functions.length >= rowsPerPage) {
                changePage(event,last.id, rowsPerPage, 'forward', page === 'history');
            }
        }

    };

    const handleChangeRowsPerPage = (event) => {
        const newRowsPerPAge = parseInt(event.target.value, 10);
        updateRowsPerPage(newRowsPerPAge);
        setRowsPerPage(newRowsPerPAge);
        changePage(event,null, newRowsPerPAge, null, page === 'history', true);
    };

    const handleClick = (event, callback, row) => {
        event.preventDefault()
        const data = {...row}
        callback(event, data);
    };

    const getActionButtons = (row) => {
        const actionButtons = [];
        let hasPerm = false;
        if(hasPermissionToHandleMunicipality(row.shareEventMunicipalityId ? row.shareEventMunicipalityId : row.municipalityId, authenticatedUser) ||
        hasPermission(PERMISSION_EDIT_ANY_MUNICIPALITY_FUNCTION_TEMPLATE, authenticatedUser)){
            if (hasPermission(PERMISSION_EDIT_ANY_FUNCTIONS, authenticatedUser)) {
                hasPerm = true;
            } else if (hasPermission(PERMISSION_EDIT_OWN_FUNCTIONS, authenticatedUser)) {
                hasPerm = row.creatorId === authenticatedUser.id;
                row.organizers.forEach((organizer) => {
                    if (organizer.id === authenticatedUser.id) {
                        hasPerm = true;
                    }
                })
            }
        }

        if (!hasPerm) return actionButtons;

        actions.forEach(function (button) {
            const label = 'label' in button ? button.label : "";
            const key = generateKey("editButton");
            const className = 'className' in button ? button.className : "";
            const icon = 'icon' in button ? button.icon : "";
            const iconAlt = 'iconAlt' in button ? button.iconAlt : "";
            const onClick = 'onClick' in button ? button.onClick : () => {
            };

            if (button.id === 'copy' && row.shareEventId) {
                return; //no copying of shared events
            }

            actionButtons.push(
                <button key={key} onClick={(event) => handleClick(event, onClick, row)} className={className}>
                    <div className={`${className}-image`}>
                        <img src={icon}
                             alt={iconAlt}/>
                    </div>
                    <div className={'editButton-text'}>{label}</div>
                </button>
            )

        });
        return actionButtons;
    };

    const getFunctionSharedInformation = (row) => {
        if (row.shareEventMunicipalityId) {
            return t('function.shared_with')+municipalities.find(m => m.id === row.municipalityId).name;
        }
        return "";
    }

    const organizersToString = (organizers) => {
        if (organizers.length > 2) {
            return organizers[0].firstName+" "+organizers[0].lastName+", "+organizers[1].firstName+" "+organizers[1].lastName+"...";
        }
        else if (organizers.length === 2) {
            return organizers[0].firstName+" "+organizers[0].lastName+", "+organizers[1].firstName+" "+organizers[1].lastName;
        }
        else if (organizers.length === 1) {
            return organizers[0].firstName+" "+organizers[0].lastName
        }
        return "";
    }

    return (
        <div className={'CompFunctionCards-container'}>
            <div className={'CompFunctionCards-row CompFunctionCards-header'}>
                <div className={'cell-image'}>
                </div>
                <div className={'cell-content'}>
                    <div className={'information'}>{t("function.card_information")}</div>
                    <div className={'organizers'}>{t("function.card_organizers")}</div>
                    <div className={'participants'}>{t("function.card_participants")}</div>
                </div>
                <div className={'cell-right'}>
                    <div className={'dates'}>{t("function.card_dates")}</div>
                </div>
            </div>
            {functions.map((row, rowIndex) => (
                <div className={'CompFunctionCards-row CompFunctionCards-verticalAlign'} key={`card-${rowIndex}`}>
                    <div className={'cell-image'} onClick={e => rowClick(e, row)}>

                        <img className="action-icon-img"
                             src={process.env.PUBLIC_URL + getFunctionIconUrl(row)}
                             alt={t("function.card_image_alt")}/>
                    </div>
                    <div className={'cell-content'} onClick={e => rowClick(e, row)}>
                        <div className={'information'}>
                            <div className={'title'}>{row.name}</div>
                            <div className={'desc'}>{getFunctionTypeLabel(row.eventType)+getFunctionSharedInformation(row)}</div>
                        </div>
                        <div className={'organizers'}>
                            {organizersToString(row.organizers)}
                        </div>
                        <div className={'participants'}>
                            {row.participants ?? 0}
                        </div>
                    </div>

                    <div className={'cell-right CompFunctionCards-verticalAlign'}>
                        <div className={'dates'} onClick={e => rowClick(e, row)}>
                            <div>{formatDateStr(row.startDatetime) + " - "}</div>
                            <div>{formatDateStr(row.endDatetime)}</div>
                        </div>
                        <div className={'actions'}>
                            {getActionButtons(row)}
                        </div>
                    </div>
                </div>
            ))}
            <div className={'CompFunctionCards-footer'}>
                <table>
                    <tbody>
                    <tr>
                        <td className="MuiTableCell-root MuiTablePagination-root">
                            <div
                                className="MuiToolbar-root MuiToolbar-regular MuiTablePagination-toolbar MuiToolbar-gutters">
                                <div className="MuiTablePagination-spacer"/>
                                <div
                                    className="MuiInputBase-root MuiTablePagination-input MuiTablePagination-selectRoot footerDropdown">
                                    <p className="MuiTypography-root MuiTablePagination-caption MuiTypography-body2 MuiTypography-colorInherit" style={{marginRight: "20%"}}
                                    >{t("components.rows_per_page")}</p>
                                    <Select
                                        className="functionCardPaginator"
                                        onChange={handleChangeRowsPerPage}
                                        value={rowsPerPage}
                                        aria-label={t("components.rows_per_page")}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem /* className="MuiTablePagination-menuItem" */ value="10">10</MenuItem>
                                        <MenuItem /* className="MuiTablePagination-menuItem" */ value="25">25</MenuItem>
                                        <MenuItem /* className="MuiTablePagination-menuItem" */ value="100">100</MenuItem>
                                    </Select>
                                    {/* <svg className="MuiSvgIcon-root MuiSelect-icon MuiTablePagination-selectIcon"
                                         focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                                        <path d="M7 10l5 5 5-5z" />
                                    </svg> */}
                                </div>
                                <div className="CompTable-paginator">

                                    <IconButton
                                        onClick={handlePreviousPage}
                                        aria-label={t('components.previous_page')}
                                    >
                                        <KeyboardArrowLeft/>
                                    </IconButton>
                                    <IconButton
                                        onClick={handleNextPage}
                                        aria-label={t('components.next_page')}
                                    >
                                        <KeyboardArrowRight/>
                                    </IconButton>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
}

CompFunctionCards.propTypes = {
    functions: PropTypes.array,
    rowClick: PropTypes.func,
    changePage: PropTypes.func,
    actions: PropTypes.array,
    authenticatedUser: PropTypes.object
};

export {
    CompFunctionCards,
}