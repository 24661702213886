import types from "./function.types";
import {API_END, API_START} from "../api/types";
import {types as auth_types} from "../auth/auth.types"

export const initialState = {
    templates: [],
    isLoadingFunctionsListData: false,
    selectedFunction: {},
    functionFieldErrors: {},
    youthData: {}
}

export default function moduleReducer(state = initialState, action) {
    switch (action.type) {
        case auth_types.AUTH_LOGIN:
            return initialState;
        case auth_types.AUTH_LOGOUT:
            return initialState;

        case types.SET_FUNCTION_TEMPLATES_LIST:
            return {
                ...state,
                templates: action.payload
            }
        case types.SET_FUNCTION:
            return {
                ...state,
                functionFieldErrors: {},
                selectedFunction: action.payload
            }
        case types.UNSET_FUNCTION:
            return {
                ...state,
                functionFieldErrors: {},
                selectedFunction: {}
            }
        case types.SET_YOUTHINFO:
            return {
                ...state,
                functionFieldErrors: {},
                youthData: action.payload
            }
        case API_START:
            if (action.payload === types.FETCH_FUNCTIONS_LIST) {
                return {
                    ...state,
                    isLoadingFunctionsListData: true
                };
            }
            return state
        case API_END:
            if (action.payload === types.FETCH_FUNCTIONS_LIST) {
                return {
                    ...state,
                    isLoadingFunctionsListData: false
                };
            }
            return state
        case types.SET_FUNCTIONS_LIST:
            return {
                ...state,
                functions: action.payload
            }
        case types.UPDATE_FUNCTION_LINKS:
            if (state.selectedFunction) {
                const objectname = "linkupdate"+action.payload.eventId;
                return {
                    ...state,
                    [objectname]: action.payload.code,
                    selectedFunction: {
                        ...state.selectedFunction,
                        feedbackCode: action.payload.code
                    }
                }
            }
            return state;
        case types.LIST_FEEDBACK:
            return {
                ...state,
                selectedFunction: {
                    ...state.selectedFunction,
                    feedbackList: action.payload
                }
            }
        case types.LIST_SHARED_MUNICIPALITIES:
            return {
                ...state,
                selectedFunction: {
                    ...state.selectedFunction,
                    sharedMunicipalities: action.payload
                }
            }
        case types.FEEDBACK_REPORT:
            return {
                ...state,
                feedbackReport: action.payload
            }
        case types.UPDATE_FUNCTION_TEMPLATE:
            return state;
        case types.DELETE_FUNCTION_TEMPLATE:
            return state;
        case types.SET_EMPTY_ACTION:
            return state;
        case types.FUNCTION_API_ERROR:
            if (action.payload == null) {
                return state;
            }
            if(typeof action.payload === "string") {
                console.log("API error: " + action.payload)
                return state
            } else if('status' in action.payload && action.payload.status === 405) {
                console.log("API error: " + action.payload.error)
                return state
            } else if('data' in action.payload) {
                console.log("API error: " + action.payload.message)
                return {
                    ...state,
                    functionFieldErrors: action.payload.data
                }
            } else {
                console.log("API error: " + action.payload)
                return state
            }
        default:
            // Do not use the default case
            // A dispatch action will pass through each of the reducers associated with this one.
            // An action at the other end of your application could affect this state
            // without being expressed in the code. This should be avoided.
            return state
    }
}
