import React from 'react';
import {Grid, Modal} from "@material-ui/core";

import {connect} from "react-redux";
import {HomeForm} from "./homeFormComponent";
import {Trans, withTranslation} from "react-i18next";
import {BalloonNotification} from "../../../components/BalloonNotification";
import Button from "@material-ui/core/Button";
import {
    createNotification,
    listNotifications,
    removeNotification,
    setNotification,
    unsetNotification,
    acceptReminder
} from "../../user/user.actions";
import {
    listMunicipalities
} from "../../municipality/municipality.actions";
import {
    hasPermission,
    PERMISSION_CREATE_SYSTEM_WIDE_NOTIFICATION,
    PERMISSION_CREATE_MUNICIPALITY_WIDE_NOTIFICATION
} from "../../../permissions";
import {formatDateStr} from "../../../utils";
import {ButtonSecondary} from "../../../components/ButtonSecondary";
import {ButtonMain} from "../../../components/ButtonMain";
import {getReminderContent, getReminderTitle} from "../../root/root.utils";
import {getFunction} from "../../function/function.actions";


const mapStateToProps = (state) => {
    return {
        authenticatedUser: state.auth.authenticatedUser,
        selectedNotification: state.user.selectedNotification,
        notifications: state.user.notifications,
        reminders: state.user.reminders
    }
}

const mapDispatchToProps = dispatch => {
    return {
        listMunicipalities: () => dispatch(listMunicipalities()),
        setNotification: (data) => dispatch(setNotification(data)),
        unsetNotification: () => dispatch(unsetNotification()),
        listNotifications: () => dispatch(listNotifications()),
        createNotification: (data, onSuccess) => dispatch(createNotification(data, onSuccess)),
        removeNotification: (id, onSuccess) => dispatch(removeNotification(id, onSuccess)),
        getFunction: (id) => dispatch(getFunction(id)),
        acceptReminder: (id) => dispatch(acceptReminder(id))
    };
}

class HomePageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.props.listMunicipalities();
        this.props.listNotifications();

        const action = this.props.action;
        let notificationModalOpen = false;
        if (action === 'create') {
            this.props.unsetNotification();
            notificationModalOpen = true;
        }

        this.state = {
            notificationModalOpen: notificationModalOpen,
            removeModalOpen: false,
            allPartitionsOpen: false,
        }

        this.handleCreateNew = this.handleCreateNew.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.remove = this.remove.bind(this);
        this.openRemoveModal = this.openRemoveModal.bind(this);
        this.closeRemoveModal = this.closeRemoveModal.bind(this);
        this.getTitle = this.getTitle.bind(this);
        this.accept = this.accept.bind(this);
    }

    handleCreateNew(event) {
        event.preventDefault()
        this.props.unsetNotification()
        this.setState({
            ...this.state,
            allPartitionsOpen: false,
            notificationModalOpen: true
        })
    }

    remove(event) {
        event.preventDefault()
        this.props.removeNotification(this.props.selectedNotification.id, () => {
            this.setState({
                ...this.state,
                removeModalOpen: false,
                notificationModalOpen: false,
            });
            this.props.listNotifications();
            return unsetNotification();
        })
    }

    openRemoveModal(event, notificationId) {
        event.preventDefault()
        this.props.setNotification({id: notificationId})
        this.setState({...this.state, removeModalOpen: true})
    }

    closeRemoveModal(event) {
        event.preventDefault()
        this.setState({...this.state, removeModalOpen: false})
    }

    closeModal(event) {
        event.preventDefault()
        this.setState({...this.state, notificationModalOpen: false})
    }

    handleChange(e, changedData) {
        let data = {
            ...this.props.selectedNotification,
            [e.target.name]: e.target.value
        }
        if (changedData) {
            data = {
                ...this.props.selectedNotification,
                ...changedData
            }
        }

        this.props.setNotification(data);
    }


    _createNotification(data) {
        this.props.createNotification(data, data => {
            this.setState({...this.state, notificationModalOpen: false})
            this.props.listNotifications();
            return setNotification(data);
        })
    }

    handleSubmit(event, props) {
        const notificationData = {...props};
        this._createNotification(notificationData);

    }

    getTitle = (notification) => {
        const titles = {
            'INSTRUCTION': this.props.t('notification.instruction_title'),
            'INFORMATION': this.props.t('notification.information_title'),
            'NOTIFICATION': this.props.t('notification.notification_title'),
        }
        if (notification.notificationType == null) {
            return "";
        }
        return titles[notification.notificationType.toUpperCase()];
    }

    accept(id) {
        this.props.acceptReminder(id);
    }

    render() {
        const {t} = this.props;

        let actionButtons = [
            {
                type: "secondary",
                label: t('common.close_without_saving'),
                className: "",
                onClick: this.closeModal
            },
            {
                type: "main",
                label: t('common.save'),
                className: "",
                onClick: this.handleSubmit
            },
        ];

        return (
            <div>
                <Grid container spacing={1}>
                    {this.props.reminders.map((row, index) => {
                        let extraElements = [
                            (<div>{formatDateStr(row.createdAt)}</div>),
                        ];
                        if(row.reminderType === 'FUNCTION_PARTICIPANTS_MISSING') {
                            extraElements.push((
                                <Button href={'/function/edit/'+row.targetId}> 
                                    <img className="reminder-button-icon-img"
                                         src={process.env.PUBLIC_URL + '/media/images/icons/IconMove.svg'}
                                         alt={t("reminder.go_to")}/>
                                    <span>{t('reminder.go_to')}</span>

                                </Button>
                            ));
                        }
                        else {
                            extraElements.push((
                                <Button onClick={() => this.accept(row.id)}> 
                                    <span>{t('reminder.accept')}</span>
                                </Button>
                            ));
                        }
                        return (
                        <Grid item sm={12} md={6} key={`reminder-grid-content-${index}`}>
                            <BalloonNotification
                                title={getReminderTitle(row.reminderType)}
                                content={getReminderContent(row.reminderType)}
                                severity={'reminder'}
                                extraElements={extraElements}
                            />
                        </Grid>
                    )})}
                    {this.props.notifications.map((row, index) => (
                        <Grid item sm={12} md={6} key={`notification-grid-content-${index}`}>
                            <BalloonNotification
                                title={this.getTitle(row)}
                                content={row.content}
                                severity={row.notificationType.toLowerCase()}
                                extraElements={[
                                    (<div>{formatDateStr(row.startDatetime)}</div>),
                                    (<div
                                        className={'bold'}>{t('notification.will_be_removed')} {formatDateStr(row.endDatetime)}</div>),
                                    (
                                        (row.notificationVisibility === "ALL_MUNICIPALITIES" && hasPermission(PERMISSION_CREATE_SYSTEM_WIDE_NOTIFICATION, this.props.authenticatedUser)) || 
                                        (row.notificationVisibility !== "ALL_MUNICIPALITIES" && hasPermission(PERMISSION_CREATE_MUNICIPALITY_WIDE_NOTIFICATION, this.props.authenticatedUser)) ? 
                                        <Button //notification removal button will be shown only if person has rights to do so
                                        className={'button-attention'}
                                        onClick={(e) => this.openRemoveModal(e, row.id)}
                                    >{t('notification.remove_notification')}</Button> : <div/>),

                                ]}
                            />
                        </Grid>
                    ))}
                </Grid>

                <Modal
                    open={this.state.notificationModalOpen}
                    onClose={this.closeModal}
                >

                    <div className={'modal'}>

                        <HomeForm
                            label={t('notification.create_new_notification')}
                            onChange={this.handleChange}
                            selectedNotification={this.props.selectedNotification}
                            fieldErrors={this.props.notificationFieldErrors}
                            actionButtons={actionButtons}
                        />
                    </div>
                </Modal>
                <Modal
                    open={this.state.removeModalOpen}
                    onClose={this.closeRemoveModal}
                >
                    <div className={'modal archiveModal'}>
                        <div className={'modalDescription'}>
                            <Trans i18nKey="notification.remove_desc"/>
                        </div>
                        <div className={'modalQuestion'}>
                            {t('notification.remove_question')}
                        </div>
                        <div className={'modalButtons'}>
                            <ButtonSecondary onClick={this.closeRemoveModal}>
                                {t('common.cancel')}
                            </ButtonSecondary>
                            <ButtonMain onClick={this.remove}>
                                {t('common.remove')}
                            </ButtonMain>
                        </div>

                    </div>
                </Modal>

            </div>
        )
    }
}

const HomePage = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(HomePageComponent));

export {HomePage};