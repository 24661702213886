import React from "react";
import {connect} from 'react-redux'
import {MUNICIPALITY_DRAFT} from "../../root/root.utils";
import {useTranslation} from "react-i18next";
import { SearchableDropdown } from "../../../components/SearchableDropdown";

const mapStateToProps = (state, ownProps) => {
    // return state;
    return {
        municipalities: state.municipality.municipalities,
        isLoadingMunicipalitiesListData: state.municipality.isLoadingMunicipalitiesListData,
        selectedMunicipality: state.municipality.selectedMunicipality,
        props: ownProps
    }
}
const ListMunicipalitiesComponent = ({
                                         municipalities,
                                         isLoadingMunicipalitiesListData,
                                         selectedMunicipality,
                                         props
                                     }) => {
    const {t} = useTranslation('translation');
    const onSelect = props.onSelect ? props.onSelect : null;

    const municipalityRows = [];
    const suffix = " - " +  t("municipality.draft");
    var selected = null;

    municipalities.forEach(row => {
        const municipalityRow = {
            label: row.name + (row.state === MUNICIPALITY_DRAFT ? suffix : ""),
            value: row.id
        };
        municipalityRows.push(municipalityRow);
        if (row.id === selectedMunicipality.id) {
            selected = municipalityRow;
        }
    });

    return (
        <SearchableDropdown
            label={t('common.select_municipality')}
            selectedValue={selected}
            rows={municipalityRows}
            name="municipalityDropdown"
            onChange={onSelect}
        />
    )
};

const ListMunicipalities = connect(mapStateToProps)(ListMunicipalitiesComponent);

export
{
    ListMunicipalities
};
