import React from 'react';
import {Application} from "../../application/MainApp";
import {SystemLogPageForm} from "./components/systemlogPage";
import {connect} from "react-redux";
import {setBreadcrumbs} from "../root/root.actions";
import {withTranslation} from "react-i18next";
import {unsetSystemLog} from "./systemlog.actions";


class SystemLogComponent extends React.Component {
    constructor(props) {
        super(props);
        props.setBreadcrumbs(props.match);
    }

    componentDidMount() {
        document.title = this.props.t('common.system_logs') + " - " + this.props.t('common.title');
    }

    render() {
        return (
            <Application>
                <SystemLogPageForm/>
            </Application>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumbs: (match) => dispatch(setBreadcrumbs(match)),
        unsetSystemLog: () => dispatch(unsetSystemLog()),
    };
}


const SystemLog = connect(
    null,
    mapDispatchToProps
)(withTranslation('translation')(SystemLogComponent));

export {SystemLog};