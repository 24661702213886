import React from 'react';
import {Application} from "../../application/MainApp";

import {connect} from "react-redux";
import {setBreadcrumbs} from "../root/root.actions";
import {UserPageForm} from "./components/userPage";
import {withTranslation} from "react-i18next";


class UserComponent extends React.Component {
    constructor(props) {
        super(props);
        props.setBreadcrumbs(props.match);
    }

    componentDidMount() {
        document.title = this.props.t('common.users') + " - " + this.props.t('common.title');
    }

    render() {
        return (
            <Application>
                <UserPageForm action={this.props.match.params.action}/>
            </Application>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumbs: (match) => dispatch(setBreadcrumbs(match)),
    };
}


const User = connect(
    null,
    mapDispatchToProps
)(withTranslation('translation')(UserComponent));

export {User};