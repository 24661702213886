import React from 'react';
import {Application} from "../../application/MainApp";
import {LocationPageForm} from "./components/locationPage";
import {setBreadcrumbs} from "../root/root.actions";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";


class LocationComponent extends React.Component {
    constructor(props) {
        super(props);
        props.setBreadcrumbs(props.match);
    }

    componentDidMount() {
        document.title = this.props.t('common.locations') + " - " + this.props.t('common.title');
    }

    render() {
        return (
            <Application>
                <LocationPageForm/>
            </Application>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumbs: (match) => dispatch(setBreadcrumbs(match)),
    };
}

const Location = connect(
    null,
    mapDispatchToProps
)(withTranslation('translation')(LocationComponent));

export {Location};