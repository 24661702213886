import {Grid,} from "@material-ui/core";
import React from "react";

import { withTranslation } from 'react-i18next';
import i18n from "../i18n";

const FooterComponent = ( props ) => {
    const {t} = props;
    const currentLang = i18n.language

    return (
        <div className={'contentsDiv'}>
            <Grid container className={'footerToolbar'} spacing={0} alignItems={'baseline'}>
                <Grid item xs={12} sm={4} md={3}>
                    <a href={'https://nuorisotilastot.fi/wp-content/uploads/2025/01/2025_01_07_Tietosuojailmoitus_nuodo.pdf'} target='_blank' rel="noopener noreferrer" className={'link-data-privacy'}>
                        {t('footer.privacy_statement')}
                    </a>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <a href={'https://nuorisotilastot.fi/2350/uncategorized/nuodo-info/'} target='_blank' rel="noopener noreferrer" className={'link-accessibility'}>
                        {t('footer.accessibility_statement')}
                    </a>

                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <div className={'copyright'}>© {t('common.application_owner')} {new Date().getFullYear()}</div>
                </Grid>
                <Grid item xs={12} sm={12} md={3} className={'footer-logo'}>
                    <img
                        src={process.env.PUBLIC_URL + (currentLang === 'fi' ? '/media/images/AVI_logo_fi_FI.svg' : '/media/images/AVI_logo_sv_SE.svg')}
                        alt={t('common.youth_work')} />
                </Grid>
            </Grid>
        </div>
    );
}
const Footer = withTranslation()(FooterComponent);
export {Footer};