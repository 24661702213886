import Typography from "@material-ui/core/Typography";
import React from "react";
import {useTranslation} from "react-i18next";

export function Copyright() {
    const {t} = useTranslation('translation');

    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '} {t('common.application_owner')} {new Date().getFullYear()}
        </Typography>
    );
}