import Typography from "@material-ui/core/Typography";
import {Drawer, List, ListItemText} from "@material-ui/core";
import React, {Component} from "react";
import {ListItemLink} from "./ListItemLink";
import {connect} from "react-redux";
import {closeMenu} from "../modules/root/root.actions";
import {withTranslation} from "react-i18next";
import {
    hasPermission,
    PERMISSION_CREATE_FUNCTION_BEHALF_OF_ANOTHER,
    PERMISSION_CREATE_FUNCTION_TEMPLATE,
    PERMISSION_CREATE_MUNICIPALITY_WIDE_NOTIFICATION,
    PERMISSION_CREATE_ORGANIZATION_WIDE_NOTIFICATION,
    PERMISSION_CREATE_OWN_FUNCTION,
    PERMISSION_CREATE_SYSTEM_WIDE_NOTIFICATION
} from "../permissions";

const mapStateToProps = state => {
    return {
        mobileOpen: state.root.mobileOpen,
        rightDrawerOpen: state.root.rightDrawerOpen,
        authenticatedUser: state.auth.authenticatedUser,
        reminders: state.user.reminders
    };

}
const mapDispatchToProps = dispatch => {
    return {
        closeMenu: () => dispatch(closeMenu())
    };
}


class RightDrawerComponent extends Component {
    render() {
        const {t} = this.props;

        const links = []
        // if (hasPermission(PERMISSION_FUNCTIONS, this.props.authenticatedUser)) {
        //     links.push({
        //         path: "/function",
        //         label: t('menu.book_participant'),
        //     });
        // }
        if (hasPermission(PERMISSION_CREATE_FUNCTION_BEHALF_OF_ANOTHER, this.props.authenticatedUser) ||
            hasPermission(PERMISSION_CREATE_OWN_FUNCTION, this.props.authenticatedUser) ||
            hasPermission(PERMISSION_CREATE_FUNCTION_TEMPLATE, this.props.authenticatedUser)) {
            links.push({
                path: "/function/create",
                label: t('menu.create_function'),
            });
            links.push({
                path: "/function",
                label: t('menu.function_history'),
            });
        }
        /* this is hidden for demo purposes only */
        /*
        if (hasPermission(PERMISSION_REPORTS, this.props.authenticatedUser)) {
            // TODO: add this functionality
            links.push({
                path: "/",
                label: t('menu.create_data_request'),
            });
        }*/
        if (hasPermission(PERMISSION_CREATE_SYSTEM_WIDE_NOTIFICATION, this.props.authenticatedUser) ||
            hasPermission(PERMISSION_CREATE_MUNICIPALITY_WIDE_NOTIFICATION, this.props.authenticatedUser) ||
            hasPermission(PERMISSION_CREATE_ORGANIZATION_WIDE_NOTIFICATION, this.props.authenticatedUser)) {
            // TODO: add this functionality
            links.push({
                path: "/notification/create",
                label: t('menu.create_announcement'),
            });
        }

        if (this.props.reminders.length !== 0) {
            links.push({
                path: "/",
                label: t('menu.open_notifications'),
                children: (<span className={`notification-balloon`}>{this.props.reminders.length}</span>)
            });
        }


        return (
            <Drawer
                className={`rightDrawer ${this.props.mobileOpen && this.props.rightDrawerOpen ? 'active' : ''}`}
                variant="permanent"
                anchor="right"
                classes={{
                    paper: 'rightDrawerPaper'
                }}
            >
                <Typography className={'drawer-title rightDrawer-title'}>
                    <span>{t('menu.shortcuts')}</span>
                </Typography>
                <List className={'drawer-list right-drawer-list'}>
                    <div>
                        {
                            links.map(({path, label, children}, index) => (
                                    <ListItemLink key={`right-item-link-${index}`} href={path}
                                                  className={`rightListItemLink`}>
                                        <ListItemText primary={label}/>
                                        {children}
                                    </ListItemLink>
                                )
                            )
                        }
                    </div>
                </List>
            </Drawer>
        )
    }
}

const RightDrawer = connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('translation')(RightDrawerComponent));

export {RightDrawer};