
import {formatISODateStr, formatTimeStr, isFunction} from "../../../utils";
import moment from "moment";


const calculateEventHours = (data, startDate, startTime, endDate, endTime, isFeedback) => {
    const startMoment = moment.utc(startDate + "T" + startTime);

    if (startDate && startTime) {
        data[isFeedback ? 'startDatetimeFeedback' : 'startDatetime'] = startMoment;
    }

    const endMoment = moment.utc(endDate + "T" + endTime);
    if (endDate && endTime) {
        data[isFeedback ? 'endDatetimeFeedback' : 'endDatetime'] = endMoment;
    }

    if (startDate && startTime &&
        endDate && endTime && !isFeedback) {
        const duration = moment.duration(endMoment.diff(startMoment));
        let hours = duration.asHours();

        if (hours < 0) hours = 0;
        //if (hours > 8) hours = 8;
        data['eventHours'] = Math.round(100*hours)/100.0;
    }
}

export const encase = (rep, value) => {
    return rep ? " ("+value+")" : "";
}

export const handleCurrentDateCalulcations = (data) => {
    const currentDate = formatISODateStr(Date.now());

    if (data['startTime']) {
        data['startDate'] = formatISODateStr(currentDate);
        data['startDatetime'] = moment.utc(currentDate + "T" + data['startTime']);
    }
    if (data['endTime']) {
        data['endDate'] = formatISODateStr(currentDate);
        data['endDatetime'] = moment.utc(currentDate + "T" + data['endTime']);
    }

    calculateEventHours(data, data['startDate'], data['startTime'], data['endDate'], data['endTime']);
}

export const handleDateTimeChange = (event, fieldName, selectedFunction, onChange = null) => {
    const data = {}
    const isFeedback = fieldName.endsWith('Feedback');

    let startDate = fieldName === 'startDate' || fieldName === 'startDateFeedback' ? 
        event.target.value : formatISODateStr(isFeedback ? selectedFunction.startDatetimeFeedback : selectedFunction.startDatetime);
    let startTime = fieldName === 'startTime' || fieldName === 'startTimeFeedback' ? 
        event.target.value : formatTimeStr(isFeedback ? selectedFunction.startDatetimeFeedback : selectedFunction.startDatetime);
    let endDate = fieldName === 'endDate' || fieldName === 'endDateFeedback' ? 
        event.target.value : formatISODateStr(isFeedback ? selectedFunction.endDatetimeFeedback : selectedFunction.endDatetime);
    let endTime = fieldName === 'endTime' || fieldName === 'endTimeFeedback' ? 
        event.target.value : formatTimeStr(isFeedback ? selectedFunction.endDatetimeFeedback : selectedFunction.endDatetime);

    if ((fieldName === 'startDate' || fieldName === 'startDateFeedback') && startTime === undefined) {
        startTime = '00:00:00';
    }
    if ((fieldName === 'startTime' || fieldName === 'startTimeFeedback') && startDate === undefined) {
        startDate = formatISODateStr(Date.now());
    }
    if ((fieldName === 'endDate' || fieldName === 'endDateFeedback') && endTime === undefined) {
        endTime = '23:59:59';
    }
    if ((fieldName === 'endTime' || fieldName === 'endTimeFeedback') && endDate === undefined) {
        endDate = formatISODateStr(Date.now());
    }

    if ((fieldName === 'startDate' || fieldName === 'startDateFeedback') && !endDate) {
        endDate = startDate;
        if (!endTime) {
            endTime = '23:59:59';
        }
        if (isFeedback) {
            data['endDatetimeFeedback'] = moment.utc(endDate + "T" + endTime);
        }
        else {
            data['endDatetime'] = moment.utc(endDate + "T" + endTime);
        }
    }
    calculateEventHours(data, startDate, startTime, endDate, endTime, isFeedback);

    if (isFunction(onChange)) {
        onChange(event, data);
    }

}