import {Checkbox, FormControl, FormControlLabel,} from "@material-ui/core";
import React from "react";
import * as PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import { CompTooltipIcon } from "./TooltipIcon";

const CompCheckbox = (props) => {
    const {rows, error, selected, name, onChange, infoMode=false, booleanmode=false, ...componentProps} = props;
    const {t} = useTranslation('translation');

    const handleTooltipClick = (event) => {
        event.stopPropagation();
      };

    return (
        <FormControl className={`comp-checkbox-group ${error ? 'error' : ''}`} aria-label={name} name={name}>
            {rows.map((row, index) => (
                <div key={`${name}-checkbox-${index}`} className="checkbox-row">
                    <FormControlLabel
                        className={`${name}-checkbox-${index}`}
                        control={
                        <Checkbox
                            checked={
                                (booleanmode && selected[row.key]) ||
                                (!booleanmode && selected != null && selected.includes(row.value))
                            }
                            onChange={(event) => onChange(event, row.value, row)}
                            disabled={row.disabled}
                            name={name}
                        />
                    }
                        label={row.label}
                        {...componentProps}
                    />
        
                    {infoMode && (
                        <div onClick={handleTooltipClick} className="tooltip-container">
                            <CompTooltipIcon>{rows[index].infoText}</CompTooltipIcon>
                        </div>
                    )}
                </div>
            ))}
        <div className={`error-label ${props.className ? 'error-' + props.className : ''}`}>
          {error && t("errors." + error)}
        </div>
      </FormControl>
    );
}

CompCheckbox.propTypes = {
    selected: PropTypes.array,
    rows: PropTypes.array,
    error: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
};

export {CompCheckbox};