import React from 'react';
import {Application} from "../../application/MainApp";
import {setBreadcrumbs} from "../root/root.actions";
import {connect} from "react-redux";
import {HomePage} from "./components/homePage";
import {withTranslation} from "react-i18next";

class HomeComponent extends React.Component {
    constructor(props) {
        super(props);
        props.setBreadcrumbs(props.match);
    }

    componentDidMount() {
        document.title = this.props.t('common.front_page') + " - " + this.props.t('common.title');
    }

    render() {
        return (
            <Application>
                <HomePage action={this.props.match.params.action}/>
            </Application>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setBreadcrumbs: (match) => dispatch(setBreadcrumbs(match)),
    };
}

const Home = connect(
    null,
    mapDispatchToProps
)(withTranslation('translation')(HomeComponent));

export {Home};